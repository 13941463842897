import { UPDATE_PROFILE } from "../../actions/profile/action-names";
import { UpdateProfileType } from "../../actions/profile/action-types";
import { apiCalling } from "../../constants";
const userpic = require("../../../assets/images/default_image.jpg");

interface ProfileState {
  isLoading: boolean;
  profile: string;
}

const initialState: ProfileState = {
  isLoading: false,
  profile: userpic,
};

export default function profileReducer(
  state: ProfileState = initialState,
  action: any
): ProfileState {
  switch (action.type) {
    case apiCalling.IS_LOADER_SHOW:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_PROFILE:
      let successAction = action as UpdateProfileType;
      return {
        ...state,
        profile: successAction.profile,
        isLoading: false,
      };
    default:
      return state;
  }
}
