export const AuthMessages = {
  imageUploadFailed : "Image uploading failed, please try again.",
  name: "Please Enter Full Name.",
  nameLimit: "Full Name can not be more than 50.",
  firstName: "Please Enter First Name.",
  firstNameLimit: "First Name can not be more than 50.",
  validName: "Please Enter only Alphabets based Input. No Digits No Special Character.",
  lastName: "Please Enter Last Name.",
  lastNameLimit: "Last Name can not be more than 50.",
  gender: "Please Select Gender.",
  email: "Please Enter Email.",
  emailMaxLimit: "Email can not be more than 100.",
  emailMinLimit: "Email can not be less than 6.",
  mobile: "Please Enter Phone Number.",
  mobileMaxLimit: "Phone Number can not be more than 15.",
  mobileMinLimit: "Phone Number can not be less than 10.",
  dob: "Please Enter Date of Birth.",
  validEmail:"Please Enter Valid Email.",
  validMobile:"Please Enter Valid Phone Number.",
  emailAlreadyexits: "Email Id already registered with us.",
  password: "Please Enter Password.",
  passwordMaxLimit: "Password can not be more than 32.",
  country: "Please Enter Country.",
  category: "Please Enter Category.",
  categoryLimit: "Category can not be more than 50.",
  description: "Please Enter Description.",
  LicenseStatus:"You are not enough old to visit store.",
  reason: "Please select Reason.",
  validPassword: "Password must have minimum 8 characters, with at least 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character.",
  forgotPasswordSuccess: "We have sent a message to your email address. Please check your inbox or spam.",
  fileTypeError: "Please select a valid image file.",
  SignUpSuccessMessage: "We have sent a confirmation email to your registered email address. Please check your inbox or spam folder and click on the link to verify your email address.",
  logoutMessage: "Do you want to logout?",
  confirmPassword: "Please enter confirm password.",
  mobileNumber: "Please Enter Mobile Number.",
  notValidAddress: "Its not a valid adress, please select some other location",
  selectAddress: "Please Enter Address",
  datingProfileIncompleteMessage: "Your Profile is incomplete for Dating. Please complete your profile so that other users can see you. Do you want to complete your profile ?",
  maxImageUploadMessage: "you can't select more that 5 images",
  imageUploadError: "Something went wrong, plz try again.",
  profileAddressHintMessage: "All fields are mandatory. Your address or exact location will never be shared with anyone other than when you request delivery.",
  mandatoryFieldsMsg : "All fields are mandatory",
  validMarijuanaCardId : "Card characters can not be more than 24",
  marijuanaCardMessage: "Do you have Marijuana Card?",
  rejectCardMesg: "Your card has been rejected due to",
  passportNumber: "Please Enter Passport Number.",
  SplitAmount: "Sum of amounts in cash and card is different than total!.",
  creditAmount: "Your credit card is not valid.",
  termsNCondMsg: "Please agree to the Terms and Policies before you submit registration.",
  
} 

export const commonMsg = {
  clearFilter: "Clear all filters?",
  EMAIL_ERR: ["Please enter an Email Id.","Please enter a valid Email Id."],
  PASS_ERR: ["Please enter your Password.","Password must have minimum 8 characters, with at least 1 upper case letter, 1 lower case letter, 1 numeric and 1 special character."],
  accSuccVryMsg: "Your account is successfully verified. Please login.",
  succRegMsg: "You are registered successfully. Please check email for activating account.",
  emailPwdIncMsg: "Email or password is incorrect",
  emailAlreadyexits: "Email Id already registered with us.",
  noBookingDetailsFound: "No detailsPlease found.",
  licensePlateNumber: " Enter Vehicle Plate Number.",
  vehicleName: "Please Enter Vehicle Name.",
  vehicleModelname: "Please Enter Vehicle Model Name",
  vehicleTypename: "Please Select Vehicle Type",
  vehicleMakename: "Please Select Vehicle Company Make",
  vehicleColorname: "Please Select Vehicle Color.",
  addressErr: "Please Enter Address.",
  zipCodeErr: "Please Enter Zip Code.",
  cityErr: "Please Enter City.",
  stateErr: "Please Select State.",
  countryErr: "Please Select Country.",
  selectVehicleErr: "Please Select Vehicle.",
  selectPaymentModeErr: "Please Select Payment Mode.",
  locBookSucc: "Parking Booked Successfully.",
  psdRstMsg: "Your reset password link has been sent to the registered Email Id.",
  psdRstSucMsg: "Password reset successful",
  userNotfound: "This Email Id is not registered with us. Please try again.",
  homeAddErr: "Please enter home address.",
  mendotoryErr: "Please select mandatory fields.",
  notiWarn: "Your browser is not supported notifications."
}

