import { PARKINID, PARKINGADDRESS, PARKINGTIME, PARKINGLATLONG, PARKINGINDEX, CLEAR_PARKING_DETAILS} from "../../actions/parkingdetails/action-names";
import { UpdateparkingType } from "../../actions/parkingdetails/action-types";
import { apiCalling } from "../../constants";

interface ParkingState {
  isLoading: boolean;
  parkingid: any;
  parkingAddress: string;
  parkingtime: any;
  parkingindex: number;
  parkinglatlong: any;
}

const initialState: ParkingState = {
  isLoading: false,
  parkingid: null,
  parkingAddress: "",
  parkingtime: "",
  parkingindex: 0,
  parkinglatlong: null,
};

export default function parkingreducer(
  state: ParkingState = initialState,
  action: any
): ParkingState {
  switch (action.type) {
    case apiCalling.IS_LOADER_SHOW:
      return {
        ...state,
        isLoading: true,
      };
    case PARKINID:
      let successAction = action as UpdateparkingType;
      return {
        ...state,
        parkingid: successAction.parkingid,
        isLoading: false,
      };

    case PARKINGADDRESS:
      let successAction1 = action as UpdateparkingType;
      return {
        ...state,
        parkingAddress: successAction1.parkingAddress,
        isLoading: false,
      };

    case PARKINGTIME:
      let successAction2 = action as UpdateparkingType;
      return {
        ...state,
        parkingtime: successAction2.parkingtime,
        isLoading: false,
      };

    case PARKINGINDEX:
      let successAction3 = action as UpdateparkingType;
      return {
        ...state,
        parkingindex: successAction3.parkingindex,
        isLoading: false,
      };

    case PARKINGLATLONG:
      let successAction4 = action as UpdateparkingType;
      return {
        ...state,
        parkinglatlong: successAction4.parkinglatlong,
        isLoading: false,
      };

    case CLEAR_PARKING_DETAILS:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
