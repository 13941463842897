import { UPDATE_NOTIFICATION } from "../../actions/notifications/action-names";
import { UpdateNotificationType } from "../../actions/notifications/action-types";
import { apiCalling } from "../../constants";

interface NotiticationState {
  isLoading: boolean;
  count: number;
}

const initialState: NotiticationState = {
  isLoading: false,
  count: 0,
};

export default function notificationReducer(
  state: NotiticationState = initialState,
  action: any
): NotiticationState {
  switch (action.type) {
    case apiCalling.IS_LOADER_SHOW:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_NOTIFICATION:
      let successAction = action as UpdateNotificationType;
      return {
        ...state,
        count: successAction.notification,
        isLoading: false,
      };
    default:
      return state;
  }
}
