import {
 UPDATE_PROFILE
} from "./action-names";

export const profileActions = {
 updateProfile,
};

function updateProfile(updateprofile: any) {
 return {
  type: UPDATE_PROFILE,
  profile: updateprofile,
  
 };
}
