import React, { useState } from "react";

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
  userRole: string;
  userName: string;
  userDetails: any;
  userJwtToken: string;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem("jwtToken");
  // JWT decode & check token validity & expiration.
  if (token) return true;
  return false;
};

const getUserRole = () => {
  const userRole = localStorage.getItem("userRole");
  // JWT decode & check token validity & expiration.
  if (userRole) return userRole;
  return "";
};

const getUserJwtToken = () => {
  const jwtToken = localStorage.getItem("jwtToken");
  // JWT decode & check token validity & expiration.
  return jwtToken ? jwtToken : "";
};

const getUserName = () => {
  const userName = localStorage.getItem("userName");
  // JWT decode & check token validity & expiration.
  if (userName) return userName;
  return "";
};

const getUserDetails = () => {
  const userDetails = localStorage.getItem("userDetails");
  // JWT decode & check token validity & expiration.
  return JSON.parse(userDetails as string);
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  const [userRole] = useState(getUserRole());
  const [userName, setUserName] = useState(getUserName());
  const [userDetails, setUserDetails] = useState(getUserDetails());
  const [userJwtToken, setUserJwtToken] = useState(getUserJwtToken());

  function authenticate(data: any, cb: any) {
    makeAuthenticated(true);
    setUserName(data.FirstName + " " + data.LastName);
    localStorage.setItem("jwtToken", `${data.JwtToken}`);
    setUserJwtToken(data.JwtToken);
    localStorage.setItem("userDetails", JSON.stringify(data));
    setUserDetails(data);
    localStorage.setItem("userName", `${data.FirstName + " " + data.LastName}`);
    setTimeout(cb, 100); // fake async
  }

  function signout(cb: any) {
    makeAuthenticated(false);
    localStorage.clear();
    localStorage.removeItem("userRole");
    localStorage.removeItem("userName");
    localStorage.removeItem("jwtToken");
    setTimeout(cb, 100);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        userRole,
        userName,
        userDetails,
        userJwtToken,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
