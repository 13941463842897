import {
  UPDATE_NOTIFICATION
} from "./action-names";

export const notificationActions = {
  updateNotification,
};

function updateNotification(count: number) {
  return {
    type: UPDATE_NOTIFICATION,
    notification: count,
  };
}
