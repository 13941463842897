// Import the functions you need from the SDKs you need
import firebase from "firebase";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  // apiKey: "AIzaSyA0QoXNi6Sg6t_rD1-kCO6J8CVnnOPVLT0",
  // authDomain: "flixvalet-customer.firebaseapp.com",
  // projectId: "flixvalet-customer",
  // storageBucket: "flixvalet-customer.appspot.com",
  // messagingSenderId: "719140860799",
  // appId: "1:719140860799:web:e9aedb6240fc0cae08204a"
  apiKey: "AIzaSyAPzDnIYeGca1VEWO-xo8XiPnv8Sleo-rI",
  authDomain: "flixvalet-customerapp.firebaseapp.com",
  projectId: "flixvalet-customerapp",
  storageBucket: "flixvalet-customerapp.appspot.com",
  messagingSenderId: "391233117911",
  appId: "1:391233117911:web:48e339481d2da412eb5a92",
  measurementId: "G-1C4CCGDPP4",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
