/* eslint-disable array-callback-return */
/*eslint no-lone-blocks: */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import "./App.css";
import { Provider, useDispatch } from "react-redux";
import indexRoutes from "./routes/indexRoutes";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers";
import AuthProvider from "../src/Context/auth";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { SnackbarProvider } from "notistack";
import { SUB_DIRCTORY, BrowserLaunch } from "./app_config";
import firebase from "./firebase";
import { postApiCall } from "./redux/actions/_common";
import { notificationActions } from "./redux/actions";
import { commonMsg } from "./utils";

function App() {
  const user = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(user as string);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    // if (window.location.protocol !== 'https:') {
    //   window.location.assign('https:' + window.location.href.substring(window.location.protocol.length));
    //   return // to stop app from mounting
    // }

    try {
      if (
        Notification.permission === "granted" &&
        firebase.messaging.isSupported()
      ) {
        const messaging = firebase.messaging();
        messaging.onMessage((payload: any) => {
          dispatch(notificationActions.updateNotification(+payload.data.badge));
        });
        messaging
          .requestPermission()
          .then(() => {
            return messaging.getToken();
          })
          .then((token) => {
            if (userDetails && userDetails.Id) {
              const postData = {
                UserId: userDetails.Id,
                BrowserDeviceToken: token,
              };
              postApiCall(BrowserLaunch, postData).then((result) => {
                dispatch(
                  notificationActions.updateNotification(
                    // +result.Data.UnreadCount
                    result.Data ? +result.Data.UnreadCount : 0
                  )
                );
              });
            }
          })
          .catch((error) => {

          });
      }
    } catch (error) {
      addToast(commonMsg.notiWarn, {
        appearance: "warning",
        autoDismiss: true,
      });
    }
  }, []);

  return (
    <div>
      <>
        <AuthProvider>
          <Router basename={SUB_DIRCTORY}>
            <Switch>
              {indexRoutes.map((prop: any, key) => (
                <Route path={prop.path} component={prop.component} key={key} />
              ))}
            </Switch>
          </Router>
        </AuthProvider>
      </>
    </div>
  );
}

const AppWrapper = () => {
  const store = createStore(rootReducer, applyMiddleware(thunk));
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </SnackbarProvider>
    </Provider>
  );
};

export default AppWrapper;
